<template>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center" style="width: 70rem; margin-top: 2rem">
            <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #0f97c7 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius: 53px">
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Consultoria Digital Express</h1>
                    <div class="text-600 mb-5">Serviço Digital e Gratuito!</div>

                    <div class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <p>
                            <strong>Empresário</strong>, se você está precisando de ajuda para melhorar algum processo na gestão do seu negócio, temos o serviço
                            certo para você. Disponibilizamos um atendimento específico, a
                            <strong>Consultoria Digital Express em Marketing ou Finanças</strong>, com duração de 4 horas, <strong>de forma gratuita</strong>,
                            para ajudá-lo a tratar de forma rápida alguns temas importantes na gestão do seu negócio.
                        </p>
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <Message :closable="false"
                            >Os dados inseridos neste formulário serão usados pelo SEBRAE/PE para a prestação da Consultoria Express de Gestão e poderão ser
                            compartilhados com terceiros unicamente para viabilizar a prestação dos serviços, na medida em que isso seja necessário. O SEBRAE/PE
                            também poderá usar os dados acima para manutenção do nosso relacionamento com você, por meio da avaliação da sua experiência com o
                            serviço contratado e recomendação de outros serviços e produtos para você e seu negócio, de acordo com seu perfil e
                            necessidades.</Message
                        >
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300" v-if="erros.length > 0">
                        <Message class="w-full" v-for="erro in erros" :key="erro" severity="error">{{ erro }}</Message>
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300">
                        <div class="p-fluid formgrid grid">
                            <Fieldset class="mb-5 w-full">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-user mr-2"></span>
                                        <span class="font-bold text-lg">Identificação</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-4">
                                        <label for="nome">CNPJ, DAP, NIRF, SICAB<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="ni" type="text" :class="{ 'p-invalid': v$.ni.$error }" />
                                        <small class="p-error" v-if="v$.ni.$error">CNPJ, DAP, NIRF, SICAB é obrigatório(a)</small>
                                    </div>
                                    <div class="field col-12 md:col-8">
                                        <label for="nome">Nome - Razão Social<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="razaoSocial" type="text" :class="{ 'p-invalid': v$.razaoSocial.$error }" />
                                        <small class="p-error" v-if="v$.razaoSocial.$error">Nome - Razão Social é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-4">
                                        <label for="cpf">CPF do Representante Legal<i class="p-error">*</i></label>
                                        <InputMask id="cpf" v-model="cpf" mask="999.999.999-99" type="text" :class="{ 'p-invalid': v$.cpf.$error }" />
                                        <small class="p-error" v-if="v$.cpf.$error">CPF do Representante Legal é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-8">
                                        <label for="nome">Nome completo do Representante Legal (Proprietário ou sócio)<i class="p-error">*</i></label>
                                        <InputText id="nome" optionLabel="nome" v-model="nome" :class="{ 'p-invalid': v$.nome.$error }"></InputText>
                                        <small class="p-error" v-if="v$.nome.$error">Nome do Representante Legal é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="nacimento">Data de Nascimento<i class="p-error">*</i></label>
                                        <Calendar id="nacimento" v-model="dataNascimento" :class="{ 'p-invalid': v$.dataNascimento.$error }" showIcon />
                                        <small class="p-error" v-if="v$.dataNascimento.$error">Data de Nascimento é obrigatória</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="email">E-mail<i class="p-error">*</i></label>
                                        <InputText id="email" type="email" v-model="email" :class="{ 'p-invalid': v$.email.$error }" />
                                        <small class="p-error" v-if="v$.email.$error">E-mail é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="telefone">Telefone para contato<i class="p-error">*</i></label>
                                        <InputMask
                                            id="telefone"
                                            mask="(99) 9 9999-9999"
                                            v-model="telefone"
                                            type="text"
                                            :class="{ 'p-invalid': v$.telefone.$error }"
                                        />
                                        <small class="p-error" v-if="v$.telefone.$error">Telefone é obrigatório</small>
                                    </div>
                                </div>
                            </Fieldset>

                            <Fieldset class="mb-5 w-full">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-home mr-2"></span>
                                        <span class="font-bold text-lg">Endereço</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-6">
                                        <label for="municipio">Selecione seu Município<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="municipio"
                                            optionLabel="nome"
                                            v-model="cidadeSelecionada"
                                            filter
                                            :options="cidadesPE"
                                            :class="{ 'p-invalid': v$.cidadeSelecionada.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.cidadeSelecionada.$error">Município é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="cep">CEP<i class="p-error">*</i></label>
                                        <InputMask id="cep" type="text" mask="99999-999" v-model="cep" :class="{ 'p-invalid': v$.cep.$error }" />
                                        <small class="p-error" v-if="v$.cep.$error">CEP é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <label for="unidadeRegional">Unidade Regional</label>
                                        <InputText id="unidadeRegional" type="text" v-model="unidadeRegional" disabled />
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <label for="endereco">Endereço comercial completo<i class="p-error">*</i></label>
                                        <InputText id="endereco" type="text" v-model="endereco" :class="{ 'p-invalid': v$.endereco.$error }" />
                                        <small class="p-error" v-if="v$.endereco.$error">Endereço comercial é obrigatório</small>
                                    </div>
                                </div>
                            </Fieldset>

                            <Fieldset class="mb-5 w-full">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-shopping-cart mr-2"></span>
                                        <span class="font-bold text-lg">Produto</span>
                                    </div>
                                </template>
                                <div class="field col-12 md:col-12">
                                    <label for="tema">Escolha o serviço para sua empresa<i class="p-error">*</i></label>
                                    <Dropdown
                                        id="tema"
                                        optionLabel="nome"
                                        v-model="temaSelecionado"
                                        filter
                                        :options="temas"
                                        :class="{ 'p-invalid': v$.temaSelecionado.$error }"
                                    ></Dropdown>
                                    <small class="p-error" v-if="v$.temaSelecionado.$error">Serviço é obrigatório</small>
                                </div>
                            </Fieldset>
                        </div>
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <Button class="w-full" label="Enviar" @click.stop="validarEnvio()"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import chamadoService from './service';

export default {
    data() {
        return {
            ni: '',
            cpf: '',
            cep: '',
            nome: '',
            email: '',
            endereco: '',
            telefone: '',
            razaoSocial: '',
            dataNascimento: '',
            temaSelecionado: '',
            cidadeSelecionada: '',
            unidadeRegional: '',
            cidadesPE: [
                { nome: 'Abreu e Lima', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Afogados da Ingazeira', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Afrânio', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Agrestina', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Água Preta' },
                { nome: 'Águas Belas', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Alagoinha', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Aliança' },
                { nome: 'Altinho', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Amaraji' },
                { nome: 'Angelim', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Araçoiaba', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Araripina', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Arcoverde', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Barra de Guabiraba', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Barreiros' },
                { nome: 'Belém de Maria' },
                { nome: 'Belém de São Francisco', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Belo Jardim', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Betânia', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Bezerros', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Bodocó', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Bom Conselho', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Bom Jardim' },
                { nome: 'Bonito', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Brejão', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Brejinho', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Brejo da Madre de Deus', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Buenos Aires' },
                { nome: 'Buíque', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Cabo de Santo Agostinho', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Cabrobó', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Cachoeirinha', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Caetés', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Calçado', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Calumbi', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Camaragibe', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Camocim de São Félix', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Camutanga' },
                { nome: 'Canhotinho', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Capoeiras', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Carnaíba', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Carnaubeira da Penha', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Carpina' },
                { nome: 'Caruaru', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Casinhas', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Catende' },
                { nome: 'Cedro', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Chã de Alegria' },
                { nome: 'Chã Grande', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Condado' },
                { nome: 'Correntes', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Cortês' },
                { nome: 'Cumaru', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Cupira', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Custódia', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Dormentes', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Escada' },
                { nome: 'Exu', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Feira Nova' },
                { nome: 'Fernando de Noronha', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Ferreiros' },
                { nome: 'Flores', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Floresta', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Frei Miguelino', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Gameleira' },
                { nome: 'Garanhuns', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Glória de Goitá' },
                { nome: 'Goiana' },
                { nome: 'Granito', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Gravatá', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Iati', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Ibimirim', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Ibirajuba', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Igarassu', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Iguaraci', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Inajá', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Ingazeira', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Ipojuca' },
                { nome: 'Ipubi', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Itacuruba', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Itaíba', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Itamaracá', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Itambé' },
                { nome: 'Itapetim', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Itapissuma', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Itaquitinga' },
                { nome: 'Jaboatão dos Guararapes', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Jaqueira' },
                { nome: 'Jataúba', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Jatobá', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'João Alfredo' },
                { nome: 'Joaquim Nabuco' },
                { nome: 'Jucati', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Jupi', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Jurema', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Lagoa do Ouro', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Lagoa do Carro' },
                { nome: 'Lagoa de Itaenga' },
                { nome: 'Lagoa dos Gatos', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Lagoa Grande', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Lajedo', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Limoeiro' },
                { nome: 'Macaparana' },
                { nome: 'Machados' },
                { nome: 'Manari', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Maraial' },
                { nome: 'Mirandiba', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Moreilandia', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Moreno', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Nazaré da Mata' },
                { nome: 'Olinda', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Orobó' },
                { nome: 'Orocó', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Ouricuri', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Palmares' },
                { nome: 'Palmeirina', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Panelas', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Paranatama', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Parnamirim', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Passira' },
                { nome: 'Paudalho' },
                { nome: 'Paulista', unidadeRegional: 'URMR (Região metropolitana do recife)'},
                { nome: 'Pedra', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Frei Miguelinho' },
                { nome: 'Pesqueira', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Petrolândia', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Petrolina', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Poção', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Pombos' },
                { nome: 'Primavera' },
                { nome: 'Quipapá' },
                { nome: 'Quixaba', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Recife', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'Riacho das Almas', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Ribeirão' },
                { nome: 'Rio Formoso' },
                { nome: 'Sairé', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Salgadinho' },
                { nome: 'Salgueiro', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Saloá', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Sanharó', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Santa Cruz', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Santa Cruz da Baixa Verde', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Santa Cruz do Capibaribe', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Santa Filomena', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Santa Maria da Boa Vista', unidadeRegional: 'USSF - Sertão do São Francisco' },
                { nome: 'Santa Maria do Cabucá', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Santa Terezinha', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'São Benedito do Sul' },
                { nome: 'São Bento do Una', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'São Caitano', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'São João', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'São Joaquim do Monte', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'São José da Coroa Grande' },
                { nome: 'São José do Belmonte' },
                { nome: 'São José do Egito', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'São Lourenço da Mata', unidadeRegional: 'URMR (Região metropolitana do recife)' },
                { nome: 'São Vicente Ferrer' },
                { nome: 'Serra Talhada', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Serrita', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Sertânia' },
                { nome: 'Sirinhaém' },
                { nome: 'Solidão', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Surubim', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Sairé' },
                { nome: 'Tabira', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Tacaimbó', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Tacaratu', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Tamandaré' },
                { nome: 'Taquaritinga do Norte', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Terezinha', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Terra Nova', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Timbaúba' },
                { nome: 'Toritama', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Tracunhaém' },
                { nome: 'Trindade', unidadeRegional: 'USA - Sertão do Araripe' },
                { nome: 'Triunfo', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Tupanatinga', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Tuparetama', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Venturosa', unidadeRegional: 'UAM - Agreste Meridional' },
                { nome: 'Verdejante', unidadeRegional: 'USC - Sertão Central, Moxotó, Pajeú e Itaparica ' },
                { nome: 'Vertério do Lério' },
                { nome: 'Vertentes', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Vertentens do Lério', unidadeRegional: 'UACS - Agreste Central e Setentrional' },
                { nome: 'Vicência' },
                { nome: 'Vitória de Santo Antão' },
                { nome: 'Xexéu' },
            ],
            temas: [
                {
                    nome: 'Marketing Digital - Aquecendo as vendas através das redes sociais',
                },
                {
                    nome: 'Marketing Digital - Presença Digital',
                },
                {
                    nome: 'Vendas pelo WhatsApp Business',
                },
                {
                    nome: 'Gestão Financeira - Gestão do Fluxo de Caixa',
                },
                {
                    nome: 'Gestão Financeira - Analisando custos, despesas e resultados',
                },
                {
                    nome: 'Gestão Financeira - Meios Digitais de Pagamento',
                },
                {
                    nome: 'Gestão Financeira - Identificação e análise dos custos, margens e formação de preço',
                },
                {
                    nome: 'Planejamento - Remodelagem do Negócio',
                },
                {
                    nome: 'Planejamento - Replanejamento Estratégico pós crise',
                },
                {
                    nome: 'Tributos no Varejo - Como a análise correta da tributação dos custos das mercadorias pode ajudar a não perder dinheiro em empresas comerciais',
                },
                {
                    nome: 'Tributos na Indústria - Como a análise correta da tributação dos custos de produção de produtos pode ajudar a não perder dinheiro nas indústrias',
                },
                {
                    nome: 'Tributos SIMPLES NACIONAL - O SIMPLES Nacional impacta nos resultados com mercadorias e substituição tributária.',
                },
            ],
            erros: [],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            ni: { required },
            cpf: { required },
            cep: { required },
            nome: { required },
            email: { required },
            endereco: { required },
            telefone: { required },
            razaoSocial: { required },
            dataNascimento: { required },
            temaSelecionado: { required },
            cidadeSelecionada: { required },
        };
    },
    methods: {
        async validarEnvio() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('formProdutoReal');
            this.enviar(token);
        },
        enviar(token) {
            const solicitacao = {
                ni: this.ni,
                cep: this.cep,
                cpf: this.cpf,
                nome: this.nome,
                email: this.email,
                endereco: this.endereco,
                telefone: this.telefone,
                razaoSocial: this.razaoSocial,
                tema: this.temaSelecionado.nome,
                dataNascimento: this.dataNascimento,
                cidade: this.cidadeSelecionada.nome,
                unidadeRegional: this.unidadeRegional,
                token: token
            };

            this.$store.dispatch('addRequest');
            chamadoService
                .solicitarConsultoriaExpress(solicitacao)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Solicitação',
                        detail: 'Solicitação enviada com sucesso',
                        life: 3000,
                    });
                    this.$router.push({ name: 'sol_centralChamados' });
                })
                .catch(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    watch: {
        cidadeSelecionada() {
            this.unidadeRegional = this.cidadeSelecionada.unidadeRegional;
        }
    }
};
</script>
